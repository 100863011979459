@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d;
}

::-webkit-scrollbar-thumb {
  background-color: #4f81e8;
  border-radius: 5px;
  border: 2px solid #2d2d2d;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3b72d6;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: #4f81e8 #2d2d2d;
  scrollbar-width: thin;
}
